//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-464:_7540,_2208,_388,_4896,_8288,_3848,_7383,_100;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-464')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-464', "_7540,_2208,_388,_4896,_8288,_3848,_7383,_100");
        }
      }catch (ex) {
        console.error(ex);
      }