import _forIn from 'lodash-es/forIn.js';
import originalConfig from 'Stores/shopify-automotive-generic/config.js';

// FIXME: we want to add this classes as soon as possible (do we?) but we should find a better place for this
// some init things

// prepare templatesByPath

// prepare templatesConfig

export const unprocessedConfig = { ...originalConfig };

// // extend config

const {
  Convermax: { config: predefinedConfig = {} },
  __CONVERMAX__: { config: legacyPredefinedConfig = {} } = {},
} = window;
const runtimeConfig = {
  ...predefinedConfig,
  ...legacyPredefinedConfig,
};
if (Object.entries(runtimeConfig).length) {
  extendConfig(runtimeConfig);
}

function extendConfig(config) {
  appendWidgets(config);
  appendFacets(config);
  appendSimpleKeys(config);
}

function appendWidgets({ Widgets: source = [] }) {
  const destination = unprocessedConfig.Widgets;

  source.forEach((widget) => {
    const baseConfig = destination.find((w) => w.name === widget.name);
    if (baseConfig) {
      _forIn(widget, (value, key) => {
        baseConfig[key] = value;
      });
    } else {
      destination.push(widget);
    }
  });
}

function appendFacets({ facets: source = {} }) {
  const destination = unprocessedConfig.facets;
  function getBaseFacetConfig(type) {
    const baseConfig = destination[type];
    if (source[type].templates && baseConfig && !baseConfig.templates) {
      return { templates: { default: { ...baseConfig } } };
    }
    return baseConfig;
  }

  _forIn(source, (config, type) => {
    // eslint-disable-next-line no-multi-assign
    const baseConfig = (destination[type] = getBaseFacetConfig(type));

    if (baseConfig) {
      const copyKeys = (deepDestination, deepSource) => {
        _forIn(deepSource, (value, key) => {
          deepDestination[key] = value;
        });
      };

      if (config.templates) {
        _forIn(config.templates, (template, name) => {
          copyKeys(baseConfig.templates[name], template);
        });
      } else {
        copyKeys(baseConfig.templates ? baseConfig.templates.default : baseConfig, config);
      }
    } else {
      destination[type] = config;
    }
  });
}

function appendSimpleKeys(config) {
  const keysToIgnore = ['Widgets', 'facets'];

  if (unprocessedConfig.storeId) {
    keysToIgnore.push('storeId');
  }

  _forIn(config, (value, key) => {
    if (!keysToIgnore.includes(key) && value) {
      unprocessedConfig[key] = value;
    }
  });
}

// // setup storeId

unprocessedConfig.storeId = unprocessedConfig.storeId || CONVERMAX_STORE_ID;

// // resolve widgets

// // check widgets conflicts

// // append request.extra

unprocessedConfig.SearchRequestDefaults = unprocessedConfig.SearchRequestDefaults || {};
if (!unprocessedConfig.SearchRequestDefaults.extra) {
  unprocessedConfig.SearchRequestDefaults.extra = {};
}

// export
export const config = {};

window.Convermax.templates = {
  config,
};
